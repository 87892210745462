/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@use '@angular/material' as mat;

$gc-pallete: (
    50: #eff8e8,
    100: #d7ecc6,
    200: #bde0a2,
    300: #a2d47d,
    400: #8eca60,
    500: #7ac143,
    600: #6bb13b,
    700: #579d31,
    800: #448928,
    900: #1d6716,
    A100: #b9f6ca,
    A200: #69f0ae,
    A400: #00e676,
    A700: #00c853,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: rgba(black, 0.87),
        A200: rgba(black, 0.87),
        A400: rgba(black, 0.87),
        A700: rgba(black, 0.87),
    ),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$my-primary: mat.define-palette($gc-pallete);
$my-accent: mat.define-palette(mat.$deep-purple-palette);

$my-theme: mat.define-light-theme(
        (
            color: (
                primary: $my-primary,
                accent: $my-accent,
            ),
        )
);

@include mat.legacy-core-theme($my-theme);
@include mat.legacy-form-field-theme($my-theme);
@include mat.legacy-button-theme($my-theme);
@include mat.datepicker-theme($my-theme);

@import 'theme/palette';
@import 'src/app/shared/dwolla/styles';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Stream Chat Cores SCSS */
@import 'stream-chat-angular/src/assets/styles/scss/index.scss';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import 'flex';

$utdirs: (
    'w': 'width',
    'h': 'height',
);
@for $i from 1 through 4 {
  @each $char, $prop in $utdirs {
    .#{$char}-#{$i*25} {
      #{$prop}: percentage(calc($i * 25 / 100));
    }
  }
}

$utbox: (
    'm': 'margin',
    'p': 'padding',
);
@for $i from 0 through 9 {
  @each $char, $prop in $utbox {
    .#{$char}-#{$i} {
      #{$prop}: #{$i}px !important;
    }
    .#{$char}y-#{$i} {
      #{$prop}-top: #{$i}px !important;
      #{$prop}-bottom: #{$i}px !important;
    }
    .#{$char}x-#{$i} {
      #{$prop}-left: #{$i}px !important;
      #{$prop}-right: #{$i}px !important;
    }
    .#{$char}t-#{$i} {
      #{$prop}-top: #{$i}px !important;
    }
    .#{$char}b-#{$i} {
      #{$prop}-bottom: #{$i}px !important;
    }
    .#{$char}l-#{$i} {
      #{$prop}-left: #{$i}px !important;
    }
    .#{$char}r-#{$i} {
      #{$prop}-right: #{$i}px !important;
    }
  }
}

@for $i from 1 through 5 {
  @each $char, $prop in $utbox {
    .#{$char}-#{$i*10} {
      #{$prop}: #{$i * 10}px !important;
    }
    .#{$char}-#{$i*10 + 5} {
      #{$prop}: #{$i * 10+5}px !important;
    }
    .#{$char}y-#{$i*10} {
      #{$prop}-top: #{$i * 10}px !important;
      #{$prop}-bottom: #{$i * 10}px !important;
    }
    .#{$char}x-#{$i*10} {
      #{$prop}-left: #{$i * 10}px !important;
      #{$prop}-right: #{$i * 10}px !important;
    }
    .#{$char}t-#{$i*10} {
      #{$prop}-top: #{$i * 10}px !important;
    }
    .#{$char}b-#{$i*10} {
      #{$prop}-bottom: #{$i * 10}px !important;
    }
    .#{$char}l-#{$i*10} {
      #{$prop}-left: #{$i * 10}px !important;
    }
    .#{$char}r-#{$i*10} {
      #{$prop}-right: #{$i * 10}px !important;
    }
  }
}

.d-block {
  display: block;
}

.muted {
  opacity: 0.4;
  text-decoration: line-through;
}

.muted > span {
  text-decoration: line-through;
}

.text-smaller {
  font-size: smaller;
}

.text-small {
  font-size: 0.75em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-title {
  text-transform: capitalize;
}

.corner-badge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.2em 1.2em;
  color: white;
  border-bottom-left-radius: 0.5em;
}

.corner-badge-yellow {
  background-color: var(--ion-color-gc-yellow);
}

.corner-badge-green {
  background-color: var(--ion-color-gc-alt-green);
}

.corner-badge-blue {
  background-color: rgba(0, 0, 0, 0.2);
}

.button-text-red {
  color: #e33939 !important;
}

.button-text-dark {
  color: var(--ion-color-dark) !important;
}

.tox-notifications-container {
  display: none !important;
}

.pointable {
  cursor: pointer;
}

.full-width {
  width: auto;
}

.full-height {
  height: 100vh;
}

.center-vertical {
  margin-top: auto;
  margin-bottom: auto;
}

ion-button.gc-button-round {
  width: 40px;
  height: 40px;
  //margin: 0;
  --padding-start: 0px;
  --padding-end: 0px;
}

ion-header {
  &.header-md {
    box-shadow: none;
  }

  &.header-outline.header-md {
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);

    &::after {
      background-image: none !important;
    }
  }

  &.header-outline-none {
    .header-md::after {
      background-image: none !important;
    }

    &.header-ios ion-toolbar:last-of-type {
      //--border-width: 0 0 0.55px;
      --border-width: 0;
    }
  }
}

@for $i from 1 through 6 {
  .gc-pt-#{$i} {
    padding-top: $i * 0.5em;
  }

  .gc-ml-#{$i} {
    margin-left: $i * 0.5em;
  }

  .gc-mb-#{$i} {
    margin-bottom: $i * 0.5em;
  }
}

@media only screen and (min-width: 768px) {
  ion-backdrop {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.5);
  }

  ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default.show-modal {
    --backdrop-opacity: 0.4;
  }

  .fullscreen-modal::part(content) {
    width: 95vw;
    height: 95vh;
    max-width: 1200px;
  }

  .larger-modal {
    --width: 600px;
    --height: 600px;
  }

  .rounded-modal::part(content) {
    border-radius: 16px;
  }

  .rounded-modal-tall::part(content) {
    border-radius: 16px;
    height: 65vh; // TODO: Figure out how to adjust this based on breakpoint
  }
}

.announcement-modal .modal-wrapper {
  width: 95vw;
  max-width: 500px;
  height: 95vh;
  max-height: 600px;
  border-radius: 16px;
}

ion-modal::part(handle) {
  background: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 992px) {
  .hide-on-mobile {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  ion-tab-bar,
  .hide-on-desktop {
    display: none;
  }
  ion-menu[menuId='gc-chat'] {
    --width: 25vw;
    --max-width: var(--width);
    --side-max-width: var(--max-width);
    --side-width: var(--width);

    &.full-screen {
      // 280px is left menu-width
      --width: calc(100vw - 280px);
    }

    &.closed {
      display: none;
      --width: 0px;
    }
  }
}

.company-avatar-chip {
  background: white;
  margin-left: -16px !important;
}

.installer-avatar-chip {
  margin-left: -6px !important;
}

.installer-avatar-chip,
.company-avatar-chip {
  padding: 30px 20px;
  margin: 0;
  border-radius: 30px;
  border: 0;
  overflow: visible;

  ion-avatar,
  ion-icon {
    width: 40px;
    height: 40px;
  }

  ion-label {
    font-weight: bold;
  }
}

.gc-badge {
  --background-color: rgba(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: 0.6em;
  text-transform: uppercase;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
  background: var(--background-color);
}

.preview-popover::part(content) {
  &.sc-ion-popover-ios {
    --box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
  }

  --width: 100%;
  --max-width: 500px;
}

.multi-select-popover::part(content) {
  --width: 100%;
  --max-width: 350px;
}

.create-invoice-progress-popover::part(content) {
  --width: 100%;
  --max-width: 350px;
}

.save-unapproved-installer-success-popover::part(content) {
  --width: 100%;
  --max-width: 350px;
}

.payment-provider-popover::part(content) {
  --width: 95vw;
  --max-width: 350px;
}

full-calendar {
  thead,
  tr.fc-scrollgrid-section-sticky {
    position: sticky;
    top: calc(var(--ion-safe-area-top, 0) + 56px);
    background: white;
    z-index: 10;
  }

  a.fc-timeline-slot-cushion {
    color: black;
  }

  table div.fc-timeline-body {
    cursor: cell;

    div.fc-highlight {
      cursor: copy;
    }

    div.fc-timeline-event-harness {
      a.fc-event {
        height: 28px;
        border-radius: 12px !important;

        &.fc-event-draggable {
          cursor: pointer;

          &:active {
            cursor: grabbing;
          }
        }
      }
    }
  }

  td.gc-resource-label {
    cursor: pointer;

    div.fc-datagrid-cell-cushion {
      display: flex;
      align-items: center;
      padding: 0;
      background: white;

      &:hover {
        background: var(--ion-color-step-100);

        span.fc-datagrid-expander-placeholder,
        .fc-datagrid-cell-main {
          background: -moz-linear-gradient(
                  left,
                  var(--ion-color-step-100) 80%,
                  rgba(255, 255, 255, 0) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
                  left,
                  var(--ion-color-step-100) 80%,
                  rgba(255, 255, 255, 0) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
                  to right,
                  var(--ion-color-step-100) 80%,
                  rgba(255, 255, 255, 0) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
        }
      }

      & > span.fc-icon {
        width: 0;
      }

      span.fc-datagrid-expander-placeholder {
        display: flex;
        align-items: center;
        opacity: 1;
        padding: 12px 4px 12px 12px;
        margin-right: 0;
        background: white;

        span.fc-icon {
          width: 2em;
          height: 2em;

          ion-avatar {
            cursor: pointer;
            width: 100%;
            height: 100%;
            border: 2px solid white;
          }
        }
      }
    }
  }

  span.fc-icon {
    & > img {
      border-radius: 50%;
    }
  }
}

#logo {
  max-width: 150px;
}

.gc-event-label {
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px !important;

  &.gc-completed {
    opacity: 0.4;
  }

  .fc-event-title {
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    padding: 2px 12px;
    margin-left: 3px;
    text-shadow: 0 1px black;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

.alert-button.alert-button-role-confirm {
  --ion-color-primary: var(--ion-color-base);
}

.download-toast {
  transform: translateY(-25px);

  &::part(header) {
    font-size: 1.3em;
    font-weight: bold;
  }
}

ion-toast::part(button) {
  border-left: 1px solid #d2d2d2;
  font-size: 15px;
}

ion-button.action-call-btn {
  // Need to make more flexible and base off specified ion-color

  //--ion-color-base: var(--ion-color-light, #f4f5f8);
  //--ion-color-base-rgb: var(--ion-color-light-rgb, 244, 245, 248);
  //--ion-color-contrast: var(--ion-color-light-contrast, #000);
  //--ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb, 0, 0, 0);
  //--ion-color-shade: var(--ion-color-light-shade, #d7d8da);
  //--ion-color-tint: var(--ion-color-light-tint, #f5f6f9);

  --background: var(--ion-color-light);
  --background-activated: var(--ion-color-gc-green-shade);
  --background-focused: var(--ion-color-gc-green-shade);
  --background-hover: var(--ion-color-gc-green-tint);

  --color: var(--ion-color-light-contrast);
  --color-activated: var(--ion-color-gc-green-contrast);
  --color-focused: var(--ion-color-gc-green-contrast);
  --color-hover: var(--ion-color-gc-green-contrast);

  --timing: 300ms;
  --timing-function: linear;
  --transition: background var(--timing) var(--timing-function);
  transition: color var(--timing) var(--timing-function);

  &::part(native):after {
    transition: var(--transition);
  }
}

/* Stream Chat SCSS */

@media only screen and (max-width: 960px) {
  ion-menu[menuId='gc-chat'] {
    --width: 100vw;
  }
}

@media only screen and (min-width: 960px) {
  ion-menu.tiled {
    stream-channel-list {
      display: none;
    }

    div.messaging.str-chat stream-thread.str-chat__thread {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      background: #ffffff;
      background: var(--white);
      z-index: 1000;
      margin: 0;
      width: 100vw;
      max-width: 100%;
    }
  }
}

.str-chat__avatar-fallback {
  background: var(--ion-color-medium);
  color: var(--ion-color-light-contrast);
}

ion-menu[menuId='main'] .ios {
  --ion-safe-area-top: 20px;
  --ion-safe-area-bottom: 20px;
}

ion-menu[menuId='gc-chat'] {
  // Channel, Header and Received Messages
  --white-snow: var(--ion-color-light);
  //// Text
  //--black: var(--ion-color-white-contrast);
  //// Text Input And Sent Messages
  //--grey-whisper: var(--ion-color-light-shade);
  //// Background
  //--grey-gainsboro: var(--ion-color-warning);
  //// message timestamps
  //--overlay-dark: var(--ion-color-dark);
  // Message List Background
  //--white: var(--ion-color-white-shade);

  // Menu Button
  --primary-color: var(--ion-color-gc-green-tint);

  .str-chat-channel {
    max-height: 100%;
  }

  .str-chat__input-flat .str-chat__textarea > textarea {
    padding: 20px 60px 20px 20px;
  }

  .str-chat__input-flat {
    padding: 8px;
  }

  .str-chat__input-flat--textarea-wrapper {
    display: block;
  }

  .str-chat__textarea textarea {
    &.expanded {
      height: 300px !important;
    }
  }

  .str-chat__input-flat-wrapper {
    flex-wrap: nowrap;
  }

  .str-chat__message-text-inner {
    padding: 5px 15px;
  }

  div.str-chat-channel.messaging {
    //max-height: calc(100vh - calc(56px + var(--ion-safe-area-bottom, 12px)));

    &.str-chat stream-thread.str-chat__thread {
      --gc-offset: calc(56px + var(--xxs-p));
      top: var(--gc-offset);
      height: calc(100vh - var(--gc-offset));
    }

    .str-chat__input-flat {
      padding-bottom: calc(var(--xs-p, 8px) + var(--ion-safe-area-bottom, 12px));
    }
  }

  &.full-screen div.str-chat-channel.messaging {
    div.str-chat__main-panel {
      padding: 0;
    }
  }

  &.tiled div.str-chat-channel.messaging {
    div.str-chat__main-panel {
      padding: 0;

      div.str-chat__list {
        padding: 0 var(--xs-p) 0;

        .str-chat__message-simple-status {
          left: unset;
          right: 8px;
          bottom: 30px;
          margin-bottom: 3px;
        }
      }
    }
  }
}

.hidden {
  display: none;
}

.nav-header {
  position: sticky;
  top: 0;

  &.header-md::after {
    background-image: none !important;
  }

  &.header-md {
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  }

  ion-title {
    font-weight: bold;
    letter-spacing: -0.2px;
  }
}

ion-card-header.bottom-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

ion-card-title > ion-icon {
  transform: translateY(3px);
}

.image-cropper-popover::part(content) {
  &.sc-ion-popover-ios {
    --box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
  }

  --width: 100%;
  --margin: 20px;
  --max-width: 600px;
}

/* Map SCSS */
.work-order-map-label {
  background: var(--ion-color-base, var(--ion-color-gc-green));
  color: var(--ion-color-contrast);
  border-radius: 4px;
  padding: 4px;

  &.map-label-selected {
    --ion-color-base: white;
  }

  &.pending {
    &:before {
      content: var(--content-text, 'Pending');
      font-size: 0.6em;
      padding-left: 10px;
      margin-right: 35px;
      margin-bottom: 5px;
      display: block;
      transform: translateY(2px);
      height: 1em;
      width: 1em;
      background-image: url('/svg/checkmark-circle.svg');
      background-size: 1em 1em;
      background-repeat: no-repeat;
    }
  }

  &.pending-not-matched {
    --content-text: 'Available!';
  }

  &.pending-is-matched {
    --content-text: 'Matched!';
  }

  &.awarded-elsewhere {
    opacity: 0.5;
  }
}

.bg-faded-blue {
  --background: var(--ion-color-gc-faded-blue);
}

.slotted-green {
  display: block;
  background-color: var(--ion-color-gc-green);
  color: var(--ion-color-gc-green-contrast);
  margin: 0;
  text-align: center;
  line-height: 50px;
}

.slotted-box-right {
  transform: translate(16px, 0px);
}

.slotted-box-left {
  transform: translate(-16px, 0px);
}

.slotted-red {
  display: block;
  background-color: var(--ion-color-danger);
  color: var(--ion-color-gc-green-contrast);
  margin: 0;
  text-align: center;
  line-height: 50px;
}

.pac-container {
  border-radius: 8px;
  margin-top: 2px;
}

.gm-ui-hover-effect {
  opacity: 0 !important;
}

.gm-style .gm-style-iw-c {
  padding: 6px 18px;
}

.gm-style-iw-d {
  padding-bottom: 6px;
}

.dwolla-plaid-verification {
  background-color: antiquewhite;
  border-top: 5px solid rgba(0, 0, 0, 0.2);
}

ion-alert.alert-danger {
  .alert-wrapper {
    .alert-head {
      background: var(--ion-color-danger);
      color: white;
    }

    .alert-message {
      padding-top: 20px !important;
      color: var(--ion-color-dark);
    }
  }
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-top: 10px;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  .flex-wrapper {
    display: flex;
    align-items: center;
  }

  &__primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }

  &__secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
  }

  &__success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &__danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &__warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  .icon {
    font-size: 70px;
    margin-right: 15px;
    max-width: 30px;
  }

  .link {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }
}

.hidden,
[hidden] {
  display: none !important;
}

fieldset {
  border: 0;
  border-radius: 8px;
  background: white;
  margin: 15px 0;
  padding-bottom: 30px;
  box-shadow: rgb(0 0 0 / 12%) 0 4px 16px;
}

legend {
  margin: 0 2px;
  padding: 0 6px;
  font-size: 1em;
  font-weight: 800;
  letter-spacing: -0.6px;
  text-transform: uppercase;
  background: black;
  color: white;
  border-radius: 4px;
}

.pulse {
  background: #7ac143;
  border: 4px solid #7ac143;
  border-radius: 50%;
  margin: 10px;
  height: 30px;
  width: 30px;

  box-shadow: 0 0 0 0 rgb(94, 146, 51);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(94, 146, 51, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(94, 146, 51, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(94, 146, 51, 0);
  }
}

.sc-ion-buttons-md-s .button-clear {
  --background-hover: transparent;
}

ion-datetime {
  --background: var(--ion-background-color, var(--background));
}

ion-segment-button {
  --indicator-color: var(--ion-background-color);
}

ion-toolbar {
  --ion-toolbar-background: var(--ion-background-color);
}

gc-review-installer-modal {
  .display-grid {
    display: none;
  }
}

gc-view-installer-profile-modal {
  .display-grid {
    margin: 5px !important;
  }

  #profile-btn {
    display: none;
  }
}

.badge-button {
  padding: 14px;
  font-size: medium;
}

.spinner-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator {
  color: red;
  transform: translateY(2px);
  border: 2px solid white;
  border-radius: 50%;
  width: 10px;
  height: 10px;

  &-absolute {
    position: absolute;
    top: -2px;
    right: -2px;
  }

  &-no-transform {
    transform: translateY(0px);
  }
}

ion-app.ios {
  ion-card-title {
    letter-spacing: -1px;
  }
}

.work-order-creation-denied,
.work-order-creation-pending {
  background: repeating-linear-gradient(45deg, #f0f0f0 0, #f0f0f0 10px, transparent 10px, transparent 20px) !important;
}

.gc-resource-label {
  .fc-datagrid-cell-main {
    padding: 12px 30px 12px 0;
    position: relative;
    z-index: 5;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 1) 80%,
            rgba(255, 255, 255, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 1) 80%,
            rgba(255, 255, 255, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
  }

  .fc-datagrid-expander {
    position: relative;
    z-index: 5;
  }
}

ion-card {
  border: 1px solid #e6e6e6;
}

gc-work-order-installer-chip {
  ion-chip {
    transform: translateX(-8px);
    margin-top: 8px;
  }
}

gc-view-installer-profile-enhanced.ion-page {
  overflow-y: auto;
  justify-content: flex-start;
}

@media screen and (min-width: 767px) {
  gc-view-installer-profile-enhanced.ion-page {
    display: flex;
    flex-direction: row;
  }
}

.rounded-badge {
  height: 25px;
  padding: 1rem;
  border-radius: 25px;
}

.modal-wrapper, .modal-shadow {
  --box-shadow: 0 0 20px #a0a0a0 !important;
}

ion-modal gc-review-installers-modal.ion-page {
  justify-content: start;
}

@media screen and (min-width: 992px) {
  gc-work-order-list.scrollable {
    ion-list {
      overflow-y: scroll !important;
      max-height: 320px !important;
    }
  }
}
