:root {
  --ion-color-gc-green: #7ac143;
  --ion-color-gc-green-rgb: 51, 154, 37;
  --ion-color-gc-green-contrast: #ffffff;
  --ion-color-gc-green-contrast-rgb: 255, 255, 255;
  --ion-color-gc-green-shade: #2d8821;
  --ion-color-gc-green-tint: #47a43b;

  --ion-color-gc-alt-green: #7cc243;
  --ion-color-gc-alt-green-rgb: 124, 194, 67;
  --ion-color-gc-alt-green-contrast: #ffffff;
  --ion-color-gc-alt-green-contrast-rgb: 255, 255, 255;
  --ion-color-gc-alt-green-shade: #6dab3b;
  --ion-color-gc-alt-green-tint: #89c856;

  --ion-color-gc-yellow: #fbb84d;
  --ion-color-gc-yellow-rgb: 251, 184, 77;
  --ion-color-gc-yellow-contrast: #000000;
  --ion-color-gc-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-gc-yellow-shade: #dda244;
  --ion-color-gc-yellow-tint: #fbbf5f;

  --ion-color-gc-alert: #fef1d8;
  --ion-color-gc-alert-rgb: 254, 241, 216;
  --ion-color-gc-alert-contrast: #000000;
  --ion-color-gc-alert-contrast-rgb: 0, 0, 0;
  --ion-color-gc-alert-shade: #e0d4be;
  --ion-color-gc-alert-tint: #fef2dc;

  --ion-color-gc-promo: #f4f9f1;
  --ion-color-gc-promo-rgb: 244, 249, 241;
  --ion-color-gc-promo-contrast: #000000;
  --ion-color-gc-promo-contrast-rgb: 0, 0, 0;
  --ion-color-gc-promo-shade: #d7dbd4;
  --ion-color-gc-promo-tint: #f5faf2;

  --ion-color-gc-light-green: #f6faf3;
  --ion-color-gc-light-green-rgb: 246, 250, 243;
  --ion-color-gc-light-green-contrast: #000000;
  --ion-color-gc-light-green-contrast-rgb: 0, 0, 0;
  --ion-color-gc-light-green-shade: #d8dcd6;
  --ion-color-gc-light-green-tint: #f7fbf4;

  --ion-color-gc-mid-green: #9eb68b;
  --ion-color-gc-mid-green-rgb: 183, 210, 162;
  --ion-color-gc-mid-green-contrast: #000000;
  --ion-color-gc-mid-green-contrast-rgb: 0, 0, 0;
  --ion-color-gc-mid-green-shade: #96a987;
  --ion-color-gc-mid-green-tint: #dae9cf;

  --ion-color-gc-gray: #707070;
  --ion-color-gc-gray-rgb: 112, 112, 112;
  --ion-color-gc-gray-contrast: #ffffff;
  --ion-color-gc-gray-contrast-rgb: 255, 255, 255;
  --ion-color-gc-gray-shade: #636363;
  --ion-color-gc-gray-tint: #7e7e7e;

  --ion-color-gc-light-gray: #f0f1f6;
  --ion-color-gc-light-gray-rgb: 240, 241, 246;
  --ion-color-gc-light-gray-contrast: #000000;
  --ion-color-gc-light-gray-contrast-rgb: 0, 0, 0;
  --ion-color-gc-light-gray-shade: #d3d4d8;
  --ion-color-gc-light-gray-tint: #f2f2f7;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-gc-blue: #007aff;
  --ion-color-gc-blue-rgb: 0, 122, 255;
  --ion-color-gc-blue-contrast: #ffffff;
  --ion-color-gc-blue-contrast-rgb: 255, 255, 255;
  --ion-color-gc-blue-shade: #006be0;
  --ion-color-gc-blue-tint: #1a87ff;

  --ion-color-gc-faded-blue: #eff4fe;
  --ion-color-gc-faded-blue-rgb: 239, 244, 254;
  --ion-color-gc-faded-blue-contrast: #000000;
  --ion-color-gc-faded-blue-contrast-rgb: 0, 0, 0;
  --ion-color-gc-faded-blue-shade: #d2d7e0;
  --ion-color-gc-faded-blue-tint: #f1f5fe;

  --ion-color-gc-dark-blue: #1c4c9b;
  --ion-color-gc-dark-blue-rgb: 28, 76, 155;
  --ion-color-gc-dark-blue-contrast: #ffffff;
  --ion-color-gc-dark-blue-contrast-rgb: 255, 255, 255;
  --ion-color-gc-dark-blue-shade: #194388;
  --ion-color-gc-dark-blue-tint: #335ea5;

  --ion-color-gc-light-blue: #00ccff;
  --ion-color-gc-light-blue-rgb: 0, 204, 255;
  --ion-color-gc-light-blue-contrast: #000000;
  --ion-color-gc-light-blue-contrast-rgb: 0, 0, 0;
  --ion-color-gc-light-blue-shade: #00b4e0;
  --ion-color-gc-light-blue-tint: #1ad1ff;

  --ion-color-gc-dark-red: #7f0000;
  --ion-color-gc-dark-red-rgb: 127,0,0;
  --ion-color-gc-dark-red-contrast: #ffffff;
  --ion-color-gc-dark-red-contrast-rgb: 255,255,255;
  --ion-color-gc-dark-red-shade: #700000;
  --ion-color-gc-dark-red-tint: #8c1a1a;

  --ion-color-gc-purple: #8a43c1;
  --ion-color-gc-purple-rgb: 138, 67, 193;
  --ion-color-gc-purple-contrast: #ffffff;
  --ion-color-gc-purple-contrast-rgb: 255, 255, 255;
  --ion-color-gc-purple-shade: #793baa;
  --ion-color-gc-purple-tint: #9656c7;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;

  --ion-color-step-50: #f2f2f2;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-400: #999999;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #737373;
  --ion-color-step-600: #666666;
  --ion-color-step-650: #595959;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;
}

$colors: 'gc-purple';

@each $color in $colors {
  .ion-color-#{$color} {
    --ion-color-base: var(--ion-color-#{$color});
    --ion-color-base-rgb: var(--ion-color-#{$color}-rgb);
    --ion-color-contrast: var(--ion-color-#{$color}-contrast);
    --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb);
    --ion-color-shade: var(--ion-color-#{$color}-shade);
    --ion-color-tint: var(--ion-color-#{$color}-tint);
  }
}

.ion-color-gc-green {
  --ion-color-base: var(--ion-color-gc-green);
  --ion-color-base-rgb: var(--ion-color-gc-green-rgb);
  --ion-color-contrast: var(--ion-color-gc-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-green-shade);
  --ion-color-tint: var(--ion-color-gc-green-tint);
}

.ion-color-gc-alt-green {
  --ion-color-base: var(--ion-color-gc-alt-green);
  --ion-color-base-rgb: var(--ion-color-gc-alt-green-rgb);
  --ion-color-contrast: var(--ion-color-gc-alt-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-alt-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-alt-green-shade);
  --ion-color-tint: var(--ion-color-gc-alt-green-tint);
}

.ion-color-gc-yellow {
  --ion-color-base: var(--ion-color-gc-yellow);
  --ion-color-base-rgb: var(--ion-color-gc-yellow-rgb);
  --ion-color-contrast: var(--ion-color-gc-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-yellow-shade);
  --ion-color-tint: var(--ion-color-gc-yellow-tint);
}

.ion-color-gc-alert {
  --ion-color-base: var(--ion-color-gc-alert);
  --ion-color-base-rgb: var(--ion-color-gc-alert-rgb);
  --ion-color-contrast: var(--ion-color-gc-alert-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-alert-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-alert-shade);
  --ion-color-tint: var(--ion-color-gc-alert-tint);
}

.ion-color-gc-promo {
  --ion-color-base: var(--ion-color-gc-promo);
  --ion-color-base-rgb: var(--ion-color-gc-promo-rgb);
  --ion-color-contrast: var(--ion-color-gc-promo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-promo-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-promo-shade);
  --ion-color-tint: var(--ion-color-gc-promo-tint);
}

.ion-color-gc-light-green {
  --ion-color-base: var(--ion-color-gc-light-green);
  --ion-color-base-rgb: var(--ion-color-gc-light-green-rgb);
  --ion-color-contrast: var(--ion-color-gc-light-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-light-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-light-green-shade);
  --ion-color-tint: var(--ion-color-gc-light-green-tint);
}

.ion-color-gc-gray {
  --ion-color-base: var(--ion-color-gc-gray);
  --ion-color-base-rgb: var(--ion-color-gc-gray-rgb);
  --ion-color-contrast: var(--ion-color-gc-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-gray-shade);
  --ion-color-tint: var(--ion-color-gc-gray-tint);
}

.ion-color-gc-light-gray {
  --ion-color-base: var(--ion-color-gc-light-gray);
  --ion-color-base-rgb: var(--ion-color-gc-light-gray-rgb);
  --ion-color-contrast: var(--ion-color-gc-light-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-light-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-light-gray-shade);
  --ion-color-tint: var(--ion-color-gc-light-gray-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-gc-blue {
  --ion-color-base: var(--ion-color-gc-blue);
  --ion-color-base-rgb: var(--ion-color-gc-blue-rgb);
  --ion-color-contrast: var(--ion-color-gc-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-blue-shade);
  --ion-color-tint: var(--ion-color-gc-blue-tint);
}

.ion-color-gc-faded-blue {
  --ion-color-base: var(--ion-color-gc-faded-blue);
  --ion-color-base-rgb: var(--ion-color-gc-faded-blue-rgb);
  --ion-color-contrast: var(--ion-color-gc-faded-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-faded-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-faded-blue-shade);
  --ion-color-tint: var(--ion-color-gc-faded-blue-tint);
}

.ion-color-gc-dark-blue {
  --ion-color-base: var(--ion-color-gc-dark-blue);
  --ion-color-base-rgb: var(--ion-color-gc-dark-blue-rgb);
  --ion-color-contrast: var(--ion-color-gc-dark-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-dark-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-dark-blue-shade);
  --ion-color-tint: var(--ion-color-gc-dark-blue-tint);
}

.ion-color-gc-light-blue {
  --ion-color-base: var(--ion-color-gc-light-blue);
  --ion-color-base-rgb: var(--ion-color-gc-light-blue-rgb);
  --ion-color-contrast: var(--ion-color-gc-light-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-light-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-light-blue-shade);
  --ion-color-tint: var(--ion-color-gc-light-blue-tint);
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger);
  --ion-color-base-rgb: var(--ion-color-danger-rgb);
  --ion-color-contrast: var(--ion-color-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-shade);
  --ion-color-tint: var(--ion-color-danger-tint);
}

.ion-color-danger-light {
  --ion-color-base: var(--ion-color-danger-light);
  --ion-color-base-rgb: var(--ion-color-danger-light-rgb);
  --ion-color-contrast: var(--ion-color-danger-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-light-rgb);
  --ion-color-shade: var(--ion-color-danger-light-shade);
  --ion-color-tint: var(--ion-color-danger-light-tint);
}

.ion-color-gc-dark-red {
  --ion-color-base: var(--ion-color-gc-dark-red);
  --ion-color-base-rgb: var(--ion-color-gc-dark-red-rgb);
  --ion-color-contrast: var(--ion-color-gc-dark-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gc-dark-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-gc-dark-red-shade);
  --ion-color-tint: var(--ion-color-gc-dark-red-tint);
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark);
  --ion-color-base-rgb: var(--ion-color-dark-rgb);
  --ion-color-contrast: var(--ion-color-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-shade);
  --ion-color-tint: var(--ion-color-dark-tint);
}
