.flex {
  display: flex;
}

.flex-center-end {
  @extend .flex;
  justify-content: center;
  align-items: flex-end;
}

.flex-end {
  @extend .flex;
  align-self: flex-end;
}

.flex-row {
  flex-direction: row;
}

@media (max-width: 575.98px) {
  .flex-row-xs {
    flex-direction: row !important;
  }
}

@media (max-width: 767.98px) {
  .flex-row-sm {
    flex-direction: row !important;
  }
}

@media (max-width: 991.98px) {
  .flex-row-md {
    flex-direction: row !important;
  }
}

@media (max-width: 1199.98px) {
  .flex-row-lg {
    flex-direction: row !important;
  }
}

@media (min-width: 1200px) {
  .flex-row-xl {
    flex-direction: row !important;
  }
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

@media (max-width: 575.98px) {
  .flex-row-reverse-xs {
    flex-direction: row-reverse !important;
  }
}

@media (max-width: 767.98px) {
  .flex-row-reverse-sm {
    flex-direction: row-reverse !important;
  }
}

@media (max-width: 991.98px) {
  .flex-row-reverse-md {
    flex-direction: row-reverse !important;
  }
}

@media (max-width: 1199.98px) {
  .flex-row-reverse-lg {
    flex-direction: row-reverse !important;
  }
}

@media (min-width: 1200px) {
  .flex-row-reverse-xl {
    flex-direction: row-reverse !important;
  }
}

.flex-column,
.flex-col {
  flex-direction: column;
}

@media (max-width: 575.98px) {
  .flex-col-xs {
    flex-direction: column !important;
  }
}

@media (max-width: 767.98px) {
  .flex-col-sm {
    flex-direction: column !important;
  }
}

@media (max-width: 991.98px) {
  .flex-col-md {
    flex-direction: column !important;
  }
}

@media (max-width: 1199.98px) {
  .flex-col-lg {
    flex-direction: column !important;
  }
}

@media (min-width: 1200px) {
  .flex-col-xl {
    flex-direction: column !important;
  }
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

@media (max-width: 575.98px) {
  .flex-col-reverse-xs {
    flex-direction: column-reverse !important;
  }
}

@media (max-width: 767.98px) {
  .flex-col-reverse-sm {
    flex-direction: column-reverse !important;
  }
}

@media (max-width: 991.98px) {
  .flex-col-reverse-md {
    flex-direction: column-reverse !important;
  }
}

@media (max-width: 1199.98px) {
  .flex-col-reverse-lg {
    flex-direction: column-reverse !important;
  }
}

@media (min-width: 1200px) {
  .flex-col-reverse-xl {
    flex-direction: column-reverse !important;
  }
}

.justify-content-start {
  justify-content: flex-start;
}

@media (max-width: 575.98px) {
  .justify-content-start-xs {
    justify-content: flex-start !important;
  }
}

@media (max-width: 767.98px) {
  .justify-content-start-sm {
    justify-content: flex-start !important;
  }
}

@media (max-width: 991.98px) {
  .justify-content-start-md {
    justify-content: flex-start !important;
  }
}

@media (max-width: 1199.98px) {
  .justify-content-start-lg {
    justify-content: flex-start !important;
  }
}

@media (min-width: 1200px) {
  .justify-content-start-xl {
    justify-content: flex-start !important;
  }
}

.justify-content-center {
  justify-content: center;
}

@media (max-width: 575.98px) {
  .justify-content-center-xs {
    justify-content: center !important;
  }
}

@media (max-width: 767.98px) {
  .justify-content-center-sm {
    justify-content: center !important;
  }
}

@media (max-width: 991.98px) {
  .justify-content-center-md {
    justify-content: center !important;
  }
}

@media (max-width: 1199.98px) {
  .justify-content-center-lg {
    justify-content: center !important;
  }
}

@media (min-width: 1200px) {
  .justify-content-center-xl {
    justify-content: center !important;
  }
}

.justify-content-end {
  justify-content: flex-end;
}

@media (max-width: 575.98px) {
  .justify-content-end-xs {
    justify-content: flex-end !important;
  }
}

@media (max-width: 767.98px) {
  .justify-content-end-sm {
    justify-content: flex-end !important;
  }
}

@media (max-width: 991.98px) {
  .justify-content-end-md {
    justify-content: flex-end !important;
  }
}

@media (max-width: 1199.98px) {
  .justify-content-end-lg {
    justify-content: flex-end !important;
  }
}

@media (min-width: 1200px) {
  .justify-content-end-xl {
    justify-content: flex-end !important;
  }
}

.justify-content-around {
  justify-content: space-around;
}

@media (max-width: 575.98px) {
  .justify-content-around-xs {
    justify-content: space-around !important;
  }
}

@media (max-width: 767.98px) {
  .justify-content-around-sm {
    justify-content: space-around !important;
  }
}

@media (max-width: 991.98px) {
  .justify-content-around-md {
    justify-content: space-around !important;
  }
}

@media (max-width: 1199.98px) {
  .justify-content-around-lg {
    justify-content: space-around !important;
  }
}

@media (min-width: 1200px) {
  .justify-content-around-xl {
    justify-content: space-around !important;
  }
}

.justify-content-between {
  justify-content: space-between;
}

@media (max-width: 575.98px) {
  .justify-content-between-xs {
    justify-content: space-between !important;
  }
}

@media (max-width: 767.98px) {
  .justify-content-between-sm {
    justify-content: space-between !important;
  }
}

@media (max-width: 991.98px) {
  .justify-content-between-md {
    justify-content: space-between !important;
  }
}

@media (max-width: 1199.98px) {
  .justify-content-between-lg {
    justify-content: space-between !important;
  }
}

@media (min-width: 1200px) {
  .justify-content-between-xl {
    justify-content: space-between !important;
  }
}

.justify-content-evenly {
  justify-content: space-evenly;
}

@media (max-width: 575.98px) {
  .justify-content-evenly-xs {
    justify-content: space-evenly !important;
  }
}

@media (max-width: 767.98px) {
  .justify-content-evenly-sm {
    justify-content: space-evenly !important;
  }
}

@media (max-width: 991.98px) {
  .justify-content-evenly-md {
    justify-content: space-evenly !important;
  }
}

@media (max-width: 1199.98px) {
  .justify-content-evenly-lg {
    justify-content: space-evenly !important;
  }
}

@media (min-width: 1200px) {
  .justify-content-evenly-xl {
    justify-content: space-evenly !important;
  }
}

.align-items-start {
  align-items: flex-start;
}

@media (max-width: 575.98px) {
  .align-items-start-xs {
    align-items: flex-start !important;
  }
}

@media (max-width: 767.98px) {
  .align-items-start-sm {
    align-items: flex-start !important;
  }
}

@media (max-width: 991.98px) {
  .align-items-start-md {
    align-items: flex-start !important;
  }
}

@media (max-width: 1199.98px) {
  .align-items-start-lg {
    align-items: flex-start !important;
  }
}

@media (min-width: 1200px) {
  .align-items-start-xl {
    align-items: flex-start !important;
  }
}

.align-items-center {
  align-items: center;
}

@media (max-width: 575.98px) {
  .align-items-center-xs {
    align-items: center !important;
  }
}

@media (max-width: 767.98px) {
  .align-items-center-sm {
    align-items: center !important;
  }
}

@media (max-width: 991.98px) {
  .align-items-center-md {
    align-items: center !important;
  }
}

@media (max-width: 1199.98px) {
  .align-items-center-lg {
    align-items: center !important;
  }
}

@media (min-width: 1200px) {
  .align-items-center-xl {
    align-items: center !important;
  }
}

.align-items-end {
  align-items: flex-end;
}

@media (max-width: 575.98px) {
  .align-items-end-xs {
    align-items: flex-end !important;
  }
}

@media (max-width: 767.98px) {
  .align-items-end-sm {
    align-items: flex-end !important;
  }
}

@media (max-width: 991.98px) {
  .align-items-end-md {
    align-items: flex-end !important;
  }
}

@media (max-width: 1199.98px) {
  .align-items-end-lg {
    align-items: flex-end !important;
  }
}

@media (min-width: 1200px) {
  .align-items-end-xl {
    align-items: flex-end !important;
  }
}

.align-items-stretch {
  align-items: stretch;
}

@media (max-width: 575.98px) {
  .align-items-stretch-xs {
    align-items: stretch !important;
  }
}

@media (max-width: 767.98px) {
  .align-items-stretch-sm {
    align-items: stretch !important;
  }
}

@media (max-width: 991.98px) {
  .align-items-stretch-md {
    align-items: stretch !important;
  }
}

@media (max-width: 1199.98px) {
  .align-items-stretch-lg {
    align-items: stretch !important;
  }
}

@media (min-width: 1200px) {
  .align-items-stretch-xl {
    align-items: stretch !important;
  }
}

.align-items-baseline {
  align-items: baseline;
}

@media (max-width: 575.98px) {
  .align-items-baseline-xs {
    align-items: baseline !important;
  }
}

@media (max-width: 767.98px) {
  .align-items-baseline-sm {
    align-items: baseline !important;
  }
}

@media (max-width: 991.98px) {
  .align-items-baseline-md {
    align-items: baseline !important;
  }
}

@media (max-width: 1199.98px) {
  .align-items-baseline-lg {
    align-items: baseline !important;
  }
}

@media (min-width: 1200px) {
  .align-items-baseline-xl {
    align-items: baseline !important;
  }
}
