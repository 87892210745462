.dwolla-submit {
  input {
    background: var(--ion-color-gc-green);

    &[disabled] {
      background: var(--ion-color-step-200);
    }

    &:hover {
      background: var(--ion-color-gc-green-tint);
    }
  }
}

#dwolla-customer-create,
#dwolla-beneficial-owners,
#dwolla-document-upload,
#dwolla-business-vcr {
  max-width: 500px;
  accent-color: var(--ion-color-gc-green);
  margin: auto;

  .dwolla-input-container {
    input {
      outline: none;

      &:focus-visible {
        border-color: var(--ion-color-gc-green);
        //box-shadow: 0px 0px 1px var(--ion-color-gc-green);
      }
    }
  }
}

#dwolla-customer-create {
  .dwolla-customer-submit {
    @extend .dwolla-submit;
  }
}

#dwolla-beneficial-owners {
  .dwolla-bo-submit {
    @extend .dwolla-submit;
  }
}

#dwolla-document-upload {
  .dwolla-document-submit {
    @extend .dwolla-submit;
  }
}

#dwolla-business-vcr {
  .dwolla-vcr-submit {
    @extend .dwolla-submit;
  }

  div.tooltip {
    text-align: center;
    margin-left: 185px;
  }
}
